#content .encabezadoBloqueNoticiaHome h1 {
  padding: 1.6em 1em 1.6em 3.5em;
  font-weight: 700;
}

#content .bloqueNoticia {
  margin: 2.5rem 0;
}

.haycollectivebannerCabecera #content .bloqueNoticia:first-of-type h2 {
  padding-right: 2.5em;
}

.haycollectivebannerCabecera #content div.encabezadoPortada.activado ~ #searchNews .bloqueNoticia:first-of-type h2 {
  padding-right: 0;
}

.listitempage {
  display: inline-block;
}
.listitempage h2 {
  font-size: 1.25rem !important;
}

#content .bloqueNoticia:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: "";
  clear: both;
  height: 0;
}

#content .bloqueNoticia span.etiqueta {
  background: var(--clr-primary, #007b22);
  color: white;
  font-weight: bold;
  padding: 0.17em 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
}
#content .bloqueNoticia span.etiqueta.none {
  display: none;
  visibility: hidden;
}
#content .bloqueNoticia span.etiqueta.none + .texto-titulo {
  padding-left: 0;
}

#content .bloqueNoticia span.fecha {
  font-weight: bold;
  margin: 1em 0;
  background: transparent;
  float: none;
  clear: both;
  font-size: 80%;
  text-transform: capitalize;
}

.capaFecha {
  margin: 0 0 2em;
  font-weight: bold;
}

#content .bloqueNoticia img {
  max-width: 100%;
}

#content .bloqueNoticia > span > a {
  text-decoration: none;
}

#content .bloqueNoticia h2 {
  font-family: "Avenir", Arial, FreeSans, sans-serif;
  line-height: 1.3em;
  text-decoration: none;
  font-size: inherit;
}

#content .bloqueNoticia h2 a {
  font-weight: bold;
  word-wrap: break-word;
  color: #222921;
  display: inline-block;
}

#content article.bloqueNoticia h2 a {
  text-decoration: none;
}

#content .bloqueNoticia h2 a .texto-titulo {
  text-decoration: underline;
}

#content .bloqueNoticia h2 a:hover,
#content .bloqueNoticia h2 a:active,
#content .bloqueNoticia h2 a:focus {
  color: white;
}
#content .bloqueNoticia h2 a:hover span.etiqueta,
#content .bloqueNoticia h2 a:active span.etiqueta,
#content .bloqueNoticia h2 a:focus span.etiqueta {
  text-decoration: none;
  background-color: #222921;
}
#content .bloqueNoticia h2 a:hover .texto-titulo,
#content .bloqueNoticia h2 a:active .texto-titulo,
#content .bloqueNoticia h2 a:focus .texto-titulo {
  text-decoration: none;
}

#content .enlaceNoticias {
  margin: 1em 0 0;
  padding: 0 1em;
}
#content .enlaceNoticias h2 {
  font-size: inherit;
}

#content .enlaceNoticias a {
  text-decoration: none;
  color: #333;
}

#content .enlaceNoticias a:hover,
#content .enlaceNoticias a:active,
#content .enlaceNoticias a:focus {
  text-decoration: underline;
  background-color: transparent;
  color: inherit !important;
  outline: 0;
}

#content div.encabezadoFolletoIconoHome {
  background: url("folleto.png") no-repeat 0;
  padding-left: 1.8em;
  font-size: 111.111%;
}

#content div.encabezadoFolletoIconoHome h1 {
  color: var(--clr-primary, #007b22);
  font-size: 1em;
  margin: 0.3rem 0;
  line-height: 1.5rem;
  font-weight: 700;
}

#content .bloqueFolleto {
  border: 1px solid #cccccc;
  padding: 1em;
  background-color: white;
  margin-top: 1rem;
}

#content .bloqueFolleto > span > a {
  text-decoration: none;
}

#link-formulario-busqueda {
  color: #222921;
  text-decoration: none;
  padding: 0.5em;
  background-color: #f8f6f6;
  display: inline-block;
  border-color: #222921;
}
#link-formulario-busqueda > i {
  margin-right: 0.3em;
}
#link-formulario-busqueda:hover, #link-formulario-busqueda:active, #link-formulario-busqueda:focus {
  color: white;
  background-color: var(--clr-primary, #007b22);
  border-color: transparent;
}

#buscador-noticias-form > div {
  margin: 0 0 1em 0;
}

#buscador-noticias-form div.busqueda > div {
  float: left;
  clear: none;
  margin-bottom: 1em;
}

#buscador-noticias-form div.busqueda > div + div + div {
  clear: left;
}

#buscador-noticias-form div.busqueda > div + div + div + div {
  clear: none;
}

#buscador-noticias-form div.busqueda > div.field, #buscador-noticias-form div.busqueda > div:first-child {
  margin-right: 1em;
}

#buscador-noticias-form div.busqueda > div input + img.calendarIcon {
  margin-left: -1em;
  margin-right: 1em;
}

#buscador-noticias-form div.busqueda > div.formControls {
  margin: 0;
  clear: left;
  padding: 0.8em 0 1em 2.8em !important;
  background: url("ico_int_lupa.png") no-repeat 0% 50%;
}

.wrapper3 .arrow {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  left: 0;
}

.wrapper3 .arrow:before, .wrapper3 .arrow:after {
  background-color: transparent;
  width: 3px;
  height: 21px;
  display: inline-block;
  position: absolute;
  border-bottom: 12px solid black;
  top: -2px;
  left: 0;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.wrapper3 .arrow:before {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.wrapper3 .arrow:after {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.wrapper3 .arrow.activado {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transform: translate(0, -6px);
  transform: translate(0, -6px);
}

.wrapper3 .arrow.activado:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.wrapper3 .arrow.activado:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.arrow {
  width: 13px;
  height: 13px;
  display: inline-block;
  position: relative;
  bottom: -1px;
  left: -10px;
  transition: 0.4s ease;
  margin-top: 2px;
  text-align: left;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  float: right;
  margin-left: 1em;
}

.arrow:before, .arrow:after {
  position: absolute;
  content: "";
  display: inline-block;
  width: 12px;
  height: 21px;
  background-color: #fff;
  transition: 0.4s ease;
}

.arrow:after {
  position: absolute;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: -5px;
  left: 5px;
}

#pagination-wrap-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 2.65em;
  z-index: 19;
}

#pagination-block {
  display: block;
  position: relative;
}

.expand-pagination-fixed-wrap {
  display: inline-block;
  margin-left: 2em;
  margin-bottom: -1px;
  border-top: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
  background-color: #f8f6f6;
  border-bottom: none;
}
@media screen and (min-width: 767px) {
  .plone-toolbar-left .expand-pagination-fixed-wrap {
    margin-left: 5rem;
  }
}
@media screen and (min-width: 767px) {
  .plone-toolbar-left-expanded .expand-pagination-fixed-wrap {
    margin-left: 15rem;
  }
}

.expand-pagination-fixed,
#content .expand-pagination-fixed {
  display: block;
  padding: 0.5em 1em;
  background-color: #f8f6f6;
  color: black;
}
.expand-pagination-fixed.wrapper3 .arrow::before,
#content .expand-pagination-fixed.wrapper3 .arrow::before {
  transform: rotate(45deg);
}
.expand-pagination-fixed.wrapper3 .arrow::after,
#content .expand-pagination-fixed.wrapper3 .arrow::after {
  transform: rotate(-45deg);
}
.expand-pagination-fixed.wrapper3 .arrow.activado,
#content .expand-pagination-fixed.wrapper3 .arrow.activado {
  transform: rotate(0);
  transform: translate(0, 4px);
}
.expand-pagination-fixed.wrapper3 .arrow.activado::before,
#content .expand-pagination-fixed.wrapper3 .arrow.activado::before {
  transform: rotate(135deg);
}
.expand-pagination-fixed.wrapper3 .arrow.activado::after,
#content .expand-pagination-fixed.wrapper3 .arrow.activado::after {
  transform: rotate(-135deg);
}
.expand-pagination-fixed:hover, .expand-pagination-fixed:active, .expand-pagination-fixed:focus,
#content .expand-pagination-fixed:hover,
#content .expand-pagination-fixed:active,
#content .expand-pagination-fixed:focus {
  background-color: #303030 !important;
  color: white;
}
.acc-highlight .expand-pagination-fixed:hover, .acc-highlight .expand-pagination-fixed:active, .acc-highlight .expand-pagination-fixed:focus,
.acc-highlight #content .expand-pagination-fixed:hover,
.acc-highlight #content .expand-pagination-fixed:active,
.acc-highlight #content .expand-pagination-fixed:focus {
  background-color: var(--clr-primary, #007b22) !important;
}
.expand-pagination-fixed:hover.wrapper3 .arrow::before, .expand-pagination-fixed:hover.wrapper3 .arrow::after, .expand-pagination-fixed:active.wrapper3 .arrow::before, .expand-pagination-fixed:active.wrapper3 .arrow::after, .expand-pagination-fixed:focus.wrapper3 .arrow::before, .expand-pagination-fixed:focus.wrapper3 .arrow::after,
#content .expand-pagination-fixed:hover.wrapper3 .arrow::before,
#content .expand-pagination-fixed:hover.wrapper3 .arrow::after,
#content .expand-pagination-fixed:active.wrapper3 .arrow::before,
#content .expand-pagination-fixed:active.wrapper3 .arrow::after,
#content .expand-pagination-fixed:focus.wrapper3 .arrow::before,
#content .expand-pagination-fixed:focus.wrapper3 .arrow::after {
  border-bottom: 12px solid white;
}

.pagination-fixed,
#content .pagination-fixed {
  display: none;
  background-color: #f8f6f6;
  border-right: 1px solid #EAEAEA;
  border-left: 1px solid #EAEAEA;
  border-top: 1px solid #f1f1f1;
  padding: 0.5em 0.5em 0 0.5em;
  text-align: center;
}
.pagination-fixed .pagination,
#content .pagination-fixed .pagination {
  margin-top: 0;
}
@media screen and (max-width: 1350px) {
  .pagination-fixed .pagination,
  #content .pagination-fixed .pagination {
    margin-right: 8em;
  }
}
@media screen and (max-width: 480px) {
  .pagination-fixed .pagination,
  #content .pagination-fixed .pagination {
    margin-right: 0;
  }
}

@media screen and (max-width: 480px) {
  #content #pagination-wrap-fixed,
  #pagination-wrap-fixed {
    z-index: 10000;
  }
  #content #pagination-wrap-fixed .pagination-fixed,
  #pagination-wrap-fixed .pagination-fixed {
    padding: 2em 2em 1.5em 2em;
  }
  #content #pagination-wrap-fixed .previous a,
  #pagination-wrap-fixed .previous a {
    padding: 0.4em 1.5em;
  }
  #content #pagination-wrap-fixed .next a,
  #pagination-wrap-fixed .next a {
    padding: 0.4em 1.5em;
  }
}
.listingBar .pag-previous-button-mob,
.listingBar .pag-next-button-mob,
#content .listingBar .pag-previous-button-mob,
#content .listingBar .pag-next-button-mob {
  display: none;
  visibility: hidden;
}
@media screen and (max-width: 1050px) {
  .listingBar .pag-previous-button-desk,
  .listingBar .pag-next-button-desk,
  #content .listingBar .pag-previous-button-desk,
  #content .listingBar .pag-next-button-desk {
    display: none;
    visibility: hidden;
  }
  .listingBar .pag-previous-button-mob,
  .listingBar .pag-next-button-mob,
  #content .listingBar .pag-previous-button-mob,
  #content .listingBar .pag-next-button-mob {
    display: inline-block;
    visibility: visible;
  }
}

/* Portada Noticias */
body:not(.template-contenedor-noticia-view) header .encabezadoPortada h1.documentFirstHeading {
  margin-top: 1em;
}

#content #noticias_listing {
  display: inline-flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  align-items: stretch;
}
#content #noticias_listing > div:first-child {
  margin-top: 0;
}

#content #noticias_listing .bloqueNoticia.short_view {
  padding: 1.5em;
  clear: none;
  margin: 0;
  width: 50%;
  box-sizing: border-box;
}

#content #noticias_listing .bloqueNoticia.short_view + .bloqueNoticia.short_view {
  background-color: #fff;
}

#content #noticias_listing .bloqueNoticia.long_view {
  width: 33%;
  margin: 0;
  background-color: #f8f6f6;
  color: #222921;
  display: inline-flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
#content #noticias_listing .bloqueNoticia.long_view.impar {
  background-color: #f1f1f1;
}

#content #noticias_listing .bloqueNoticia.full_width {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
#content #noticias_listing .bloqueNoticia.full_width h2 {
  font-size: 1.6em;
  line-height: 1.1em;
  margin-bottom: 1.2em;
}
#content #noticias_listing .bloqueNoticia.full_width .bloqueNoticia-contentWrap {
  margin-left: 3%;
  flex-basis: 64%;
}
#content #noticias_listing .bloqueNoticia.full_width .bloqueNoticia-imageWrap {
  flex-basis: 38%;
}
#content #noticias_listing .bloqueNoticia.full_width .bloqueNoticia-imageWrap img {
  width: 100%;
}

#content #noticias_listing .bloqueNoticia.long_view .bloqueNoticia-contentWrap {
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

#content #noticias_listing .bloqueNoticia.long_view .bloqueNoticia-contentWrap:only-child {
  width: 100%;
}

#content .bloqueNoticia .bloqueNoticia-contentWrap > div {
  margin-bottom: 1em;
  line-height: 1.5em;
}

#content .bloqueNoticia .bloqueNoticia-contentWrap > div.fecha-wrap {
  margin-bottom: 0;
}

#content #noticias_listing .bloqueNoticia.long_view .bloqueNoticia-imageWrap {
  line-height: 0;
}

#content #noticias_listing .bloqueNoticia.long_view .bloqueNoticia-imageWrap img {
  width: 100%;
  max-width: 100%;
  height: 13em;
  object-fit: cover;
}

@media (max-width: 680px) {
  .desktop #content #noticias_listing .bloqueNoticia.long_view {
    flex-wrap: wrap;
  }
  .desktop .bloqueNoticia-contentWrap {
    width: 100% !important;
  }
  .desktop .bloqueNoticia-imageWrap {
    width: 100% !important;
  }
  .desktop #content #noticias_listing .bloqueNoticia.long_view.impar .bloqueNoticia-imageWrap,
  .desktop #content #noticias_listing .bloqueNoticia.long_view.par .bloqueNoticia-imageWrap {
    position: relative;
  }
}
.tipoNoticia {
  font-weight: bold;
}

.bloqueNoticia .imagen-noticia {
  float: left;
  clear: both;
  margin: 0 1em 1em 0;
  max-width: 40%;
}

.bloqueNoticia .imagen-noticia.contenedorImagenNoticia {
  width: 10em;
}

#content .bloqueNoticia .bloqueNoticia-contentWrap div.capaPadreElementosRelacionados {
  margin: 0;
  padding-top: 1.3em;
}

@media screen and (max-width: 767px) {
  #content #noticias_listing .bloqueNoticia.long_view, #content #noticias_listing .bloqueNoticia.full_width {
    width: 100%;
  }
  #content #noticias_listing .bloqueNoticia.full_width {
    flex-direction: column;
  }
  #content #noticias_listing .bloqueNoticia.full_width .bloqueNoticia-contentWrap {
    margin-left: 0;
  }
}
@media screen and (max-width: 520px) {
  #content #noticias_listing .bloqueNoticia.short_view {
    width: 100%;
    padding: 15px;
  }
  .bloqueNoticia .imagen-noticia {
    max-width: 100%;
    float: none;
    margin: 1em 0 1em 0;
  }
}
@media screen and (max-width: 480px) {
  .is_home #content .encabezadoPortada h1 {
    padding: 1em 15px 1em 15px;
  }
  #content #noticias_listing .bloqueNoticia.full_width .bloqueNoticia-contentWrap {
    padding: 0 15px;
  }
  #content #noticias_listing .bloqueNoticia.long_view .bloqueNoticia-contentWrap {
    padding: 1em 15px;
  }
}

